<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;min-width:max-content;width:calc(100% - 32px)">
                    <a-form-model-item label="话题类型" style="margin-bottom:10px;"
                                       :label-col="formItemLayout.labelCol"
                                       :wrapper-col="formItemLayout.wrapperCol">
                        <a-dropdown :trigger="['click']">
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Topic.ID">
                                {{ Topic.Name }}<a-icon type="down" />
                            </a>
                            <a-menu slot="overlay">
                                <template v-for="item in MaterialTopicList">
                                    <a-menu-item :key="item.ID">
                                        <a @click="changeTopic(item)">{{ item.Name }}</a>
                                    </a-menu-item>
                                </template>
                            </a-menu>
                        </a-dropdown>
                    </a-form-model-item>
                    <a-form-model-item label="行业类型" style="margin-bottom:10px;"
                                       :label-col="formItemLayout.labelCol"
                                       :wrapper-col="formItemLayout.wrapperCol">
                        <a-dropdown :trigger="['click']">
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Business.ID">
                                {{ Business.Name }}<a-icon type="down" />
                            </a>
                            <a-menu slot="overlay">
                                <template v-for="item in MaterialBusinessList">
                                    <a-menu-item :key="item.ID">
                                        <a @click="changeBusiness(item)">{{ item.Name }}</a>
                                    </a-menu-item>
                                </template>
                            </a-menu>
                        </a-dropdown>
                    </a-form-model-item>
                    <template v-for="info in tableData">
                        <a-card hoverable style="width: 240px;float:left;height:300px; margin:30px" :key="info.MaterialID" @click="OpenDetail(info)">
                            <img slot="cover" :src="Getsrc(info)" style="height:150px" />
                            <a-card-meta :title="info.Material.Name">
                                <template slot="description">
                                    {{info.Material.Author }}
                                    <br />
                                    {{ToFixed(info.PriceMoney) }}
                                    <br />
                                    {{TimeStamptoDateTime(info.PublishTime)}}
                                    <br />
                                    {{info.Material.Topic.Name }}
                                </template>
                            </a-card-meta>
                        </a-card>
                    </template>
                    <div style="float:left; text-align:center;width:100%">
                        <a-pagination v-model="current" :total="total" :defaultPageSize="defaultPageSize" :showTotal="showTotal" @change="pagerChange" />
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "MaterialList",
        data() {
            return {
                tableData: [],
                MaterialTopicList: [],
                Topic: { Name: "全部", ID: 0 },
                MaterialBusinessList: [],
                Business: { Name: "全行业", ID: 0 },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                current: 1,
                total: 0,
                defaultPageSize: 10,
                showTotal: total => `共 ${total} 条数据`
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            GetMaterialTopic: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTopic",
                    data: {
                    },
                    OnSuccess: function (data) {
                        self.MaterialTopicList.push({ Name: "全部", ID: 0 });
                        data.data.forEach(e => { self.MaterialTopicList.push({ Name: e.Name, ID: e.ID }); });
                        self.GetMaterialBusiness();
                    }
                };
                http.Post(op);
            },
            GetMaterialBusiness: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialBusiness",
                    data: {
                    },
                    OnSuccess: function (data) {
                        self.MaterialBusinessList = data.data;
                        self.getTableData();
                    }
                };
                http.Post(op);
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialStoreList",
                    data: {
                        Topic: self.Topic.ID,
                        pageIndex: self.current,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.defaultPageSize = data.data.RowCount;
                        self.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            changeTopic: function (e) {
                var self = this;
                self.Topic = e;
                self.current = 1;
                self.getTableData();
            },
            changeBusiness: function (e) {
                var self = this;
                self.Business = e;
                self.current = 1;
                self.getTableData();
            },
            ToFixed: function (e) {
                return util.ToFixed(e);
            },
            TimeStamptoDateTime: function (e) {
                return util.TimeStamptoDateTime(e, 'yyyy-MM-dd hh:mm:ss');
            },
            OpenDetail(e) {
                var self = this;
                self.$router.push({ name: "Store_Detail", query: { id: e.ID } });
            },
            pagerChange: function (item) {
                var self = this;
                self.current = item;
                self.getTableData();
            },
            Getsrc: function (info) {
                return info.Material.Content.ImgTopic == null ? info.Material.Logo : info.Material.Content.ImgTopic;
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.GetMaterialTopic();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>